import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import styled from 'styled-components'
import { Button } from '../elements'
import PostItem from "../components/postItem"
import { graphql } from 'gatsby'

const PostFeed = styled(AnimatedBox)` 
position: relative;
display: flex;
flex-wrap: wrap;
`

const PBox = styled(AnimatedBox)`
max-width: 1400px;
margin: 0 auto;
padding-bottom: 3rem;
`

const PButton = styled(Button)<{ color: string }>`
background: ${props => (props.color === 'white' ? 'black' : props.color)};
`

const NotFound = (props) => {
  const { data: { allNotion } } = props
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  
  return (
    <Layout color='black'>
      <SEO title="Page not found ⚠ | Conrad" desc="Looks like we stumbled into unknown territory here." />
      <PBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]} >
        <h1>Page Not Found ⚠</h1>
        <p>
        Looks like we stumbled into unknown territory here. Click a link below to get back to your quest! 👇
        </p>
        <PostFeed py={[4]} style={pageAnimation}>
        {
            allNotion.edges.map(e => <PostItem data={e} />)
        }
      </PostFeed>
        {/* <h2>Resources 📚</h2>
        <p>
        This project was made possible with...
        </p>
        <ul>
          <li>Gatsby Starter Template: <a href="https://github.com/LekoArts/gatsby-starter-portfolio-jodie" target="_blank">LekoArts/gatsby-starter-portfolio-jodie</a></li>
          <li>Notion Plugin: <a href="https://github.com/mayneyao/gatsby-starter-notion" target="_blank">mayneyao/gatsby-starter-notion</a></li>
          <li>Blog Components: <a href="https://github.com/scttcper/gatsby-casper" target="_blank">scttcper/gatsby-casper</a></li>
          <li>Mailing List Plugin: <a href="https://github.com/benjaminhoffman/gatsby-plugin-mailchimp" target="_blank">benjaminhoffman/gatsby-plugin-mailchimp</a></li>
        </ul> */}
        {/*
        <h3>Known Bugs 🐞</h3>
        <p>
        I am just picking up gatsby.js, and notion.so integration is experimental - so any suggestions are helpful!
        </p>
        <ul>
          <li>All clear! ✨</li>
        </ul>
        */}
      <PBox style={{ textAlign: 'center' }} py={1} px={[6, 6, 8, 10]}>
        <h2>Have Any Suggestions?</h2>
        <a href="mailto:lets.talk@conradlin.com" target="_blank">
        <PButton color="white" py={4} px={8}>
          Let Me Know
        </PButton>
        </a>
        </PBox>
      </PBox>
    </Layout>
  )
}

export default NotFound
export const query = graphql`
  query {
    allNotion(
        filter: {properties: {status: {value: {name: {eq: "published"}}}}}
        limit: 2
        sort: {fields: childMdx___frontmatter___publish_date___start, order: DESC}
      ) {
        edges {
          node {
            localImage {
              childImageSharp {
                fluid(quality: 95, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            childMdx {
              frontmatter {
                title
                tags {
                  name
                }
                content_type {
                  name
                }
                desc
                status {
                  name
                }
                url
                read_time
                publish_date {
                  start(fromNow: false, formatString: "YYYY-MMM-DD")
                }
              }
              timeToRead
            }
          }
        }
    }
  }
`
